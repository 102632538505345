import { template as template_8820b80ac4d643aebd93339d1dc2df97 } from "@ember/template-compiler";
const SidebarSectionMessage = template_8820b80ac4d643aebd93339d1dc2df97(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
