import { template as template_2205e1b4a12841f1bf56e3ed909358b6 } from "@ember/template-compiler";
import icon from "discourse-common/helpers/d-icon";
const InputTip = template_2205e1b4a12841f1bf56e3ed909358b6(`
  <div
    class="tip {{if @validation.failed 'bad' 'good'}}"
    id={{@id}}
    ...attributes
  >
    {{#if @validation.reason}}
      {{icon (if @validation.failed "xmark" "check")}}
      {{@validation.reason}}
    {{/if}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default InputTip;
